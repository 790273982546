import "core-js/modules/es.array.reduce.js";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts
  },
  props: {
    campaignLabels: {
      required: true,
      type: Array
    },
    CampaignCount: {
      required: true,
      type: Array
    }
  },
  data: function () {
    return {
      campaign: ""
    };
  },
  methods: {},
  computed: {
    chartOptions: function () {
      return {
        colors: this.campaignLabels[1],
        tooltip: {
          y: {
            formatter: undefined,
            title: {
              formatter: seriesName => {
                return seriesName.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
              }
            }
          }
        },
        legend: {
          fontSize: "14px",
          fontWeight: 400,
          floating: false,
          offsetX: 0,
          offsetY: 0,
          height: 300,
          itemMargin: {
            horizontal: 30,
            vertical: 15
          },
          formatter: function (seriesName, opts) {
            let total = 0;
            let series = opts.w.globals.series;
            let singleSeries = opts.w.globals.series[opts.seriesIndex];
            for (let i = 0; i < series.length; i++) {
              total += series[i];
            }
            let perCentage = parseInt(singleSeries / total * 100);
            // series name remove underscore and capitalize first letter of each word
            seriesName = seriesName.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            return `
                         <div style='display:inline-grid; width:300px;'> 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:100px;margin:0' >${singleSeries.toLocaleString()}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:100px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: this.campaignLabels[0],
        plotOptions: {
          pie: {
            size: 200,
            expandOnClick: false,
            donut: {
              size: "88%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: -10,
                  formatter: function (val) {
                    // remove underscore and capitalize each word after a space
                    return val.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
                      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                  }
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "29px",
                  lineHeight: "32px",
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  label: "Total Messages",
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "13px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0).toLocaleString();
                  }
                }
              }
            }
          }
        },
        series: this.CampaignCount,
        responsive: [{
          breakpoint: 769,
          options: {
            legend: {
              position: "bottom",
              height: 200,
              width: 240,
              itemMargin: {
                horizontal: 0,
                vertical: 0
              },
              formatter: function (seriesName, opts) {
                let total = 0;
                let series = opts.w.globals.series;
                let singleSeries = opts.w.globals.series[opts.seriesIndex];
                for (let i = 0; i < series.length; i++) {
                  total += series[i];
                }
                let perCentage = parseInt(singleSeries / total * 100);
                // series name remove underscore and capitalize first letter of each word
                seriesName = seriesName.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }).toLocaleString();
                return `
                        <div style='display:inline-grid; width:100px' > 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:100px;margin:0' >${singleSeries.toLocaleString()}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:100px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
              }
            }
          }
        }, {
          breakpoint: 1025,
          options: {
            legend: {
              height: 220,
              itemMargin: {
                horizontal: 2,
                vertical: 15
              }
            },
            formatter: function (seriesName, opts) {
              let total = 0;
              let series = opts.w.globals.series;
              let singleSeries = opts.w.globals.series[opts.seriesIndex];
              for (let i = 0; i < series.length; i++) {
                total += series[i];
              }
              let perCentage = parseInt(singleSeries / total * 100);
              // series name remove underscore and capitalize first letter of each word
              seriesName = seriesName.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
              }).toLocaleString();
              return `
                         <div style='display:inline-grid; width:200px'> 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:100px;margin:0' >${singleSeries.toLocaleString()}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:100px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
            }
          }
        }]
      };
    }
  },
  created() {}
};