<script>
  import $ from "jquery";
  import appConfig from "@/app.config";
  import SenderIDModal from "./senderIDModal.vue";
  import store from "@/state/store.js";
  import RecentSMSChart from "./recent_sms.vue";
  import BillingHistoryChart from "./BillingHistoryChart.vue";
  import loginType from "@/helpers/loginType";
  export default {
    page: {
      title: "Overview",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
      SenderIDModal,
      RecentSMSChart,
      BillingHistoryChart,
    },
    data() {
      return {
        data: {
          id: "",
          key: "",
        },
        loading: false,
        campaignDetails: [],
        groupTotal: 0,
        contactTotal: 0,
      };
    },
    beforeMount() {
      if (store.getters["senderid/allSenderIDs"].length == 0) {
        store.dispatch("senderid/getSenderIDs");
      }
      store.dispatch("counts/getCampaignCount");
      // Controls bar chart
      store.dispatch("reporting/fetchCampaignBillingSummary");
      store.dispatch("groups/listGroups", 1).then((data) => {
        this.groupTotal = data.data.data.total;
      });
      store.dispatch("contacts/listContacts", 1).then((data) => {
        this.contactTotal = data?.data?.data?.total ?? 0;
      });
    },
    mounted() {
      this.loading = true;
      // store.dispatch('reporting/fetchSMSHistory')
      // 	.then(data => {
      // 		this.campaignDetails = data
      // 	}).finally(() => this.loading = false)
      // Controls pie chart
      if (loginType() == "sms") {
        store.dispatch("reporting/fetchCampaignSummary");
      }
      // Analytics
      this.$mixpanel.track("Overview Page");
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Overview Page");
      }
    },
    computed: {
      logintype() {
        return loginType();
      },
      camapaignCount() {
        return store.getters["counts/camapaignCount"];
      },
      fetchingData() {
        return store.getters["reporting/fetchingData"];
      },
      creditUsed() {
        return store.getters["reporting/creditUsed"];
      },
      campaignLabels() {
        return store.getters["reporting/campaignLabels"];
      },
      campaignSeries() {
        return store.getters["reporting/campaignSeries"];
      },
      categories() {
        return store.getters["reporting/billingSumarryCategory"];
      },
      totalRecipients() {
        return store.getters["reporting/billingSumarryRecipients"];
      },
      senderIDs() {
        return store.getters["senderid/allSenderIDs"];
      },
      loadingSenderIDS() {
        return store.getters["senderid/loadingSenderIDS"];
      },
      contactsCount() {
        return this.contactTotal || 0;
      },
      groupsCount() {
        return this.groupTotal || 0;
      },
    },
    methods: {
      hideModal() {
        let element = this.$refs.senderID.$el;
        $(element).modal("hide");
      },
      openSenderID() {
        store.dispatch("senderid/setSenderModalState", {
          title: "Register a new Sender ID",
          type: "newSenderID",
          btnTitle: this.$t("dashboard.sender_id.register"),
        });
        let element = this.$refs.senderID.$el;
        $(element).modal("show");
      },
      deleteAction(id, key) {
        store.dispatch("senderid/setSenderModalState", {
          title: this.$t("dashboard.sender_id.delete_id"),
          type: "delete",
          btnTitle: "",
        });
        this.data = {
          id: id,
          key: key,
        };
        let element = this.$refs.senderID.$el;
        $(element).modal("show");
      },
    },
  };
</script>
<style scoped lang="scss">
  @import url("../../../styles/__overview.scss");
  @import url("../../../styles/list.scss");

  .modal-dialog {
    overflow-y: initial;
  }

  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }

  button.btn.tool_tip_btn {
    margin-left: 2em;
  }

  @media screen and (max-width: 400px) {
    .message-padding {
      padding: 0px;
      margin: 0px;
      padding-right: 0.5em;
    }

    .messages-round,
    .amount-round {
      margin: 0px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1237px) {
    ._min_header {
      width: 100%;
      flex-direction: column;
    }

    .__min_header_text {
      margin-bottom: 1em;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1119px) {
    button.btn.tool_tip_btn {
      margin-left: 0.8em !important;
    }

    .__item_text span {
      display: inline-block;
      width: 70px;
    }

    .sender__icon {
      margin: 0px !important;
    }
  }
</style>
<style scoped>
  .sender-id-container {
    height: 100px;
    position: sticky;
    top: 10%;
  }

  /* width */
  .contains-sender-id-data::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .contains-sender-id-data::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .contains-sender-id-data::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197);
    border-radius: 10px;
  }

  /* Handle on hover */
  .contains-sender-id-data::-webkit-scrollbar-thumb:hover {
    background: #b8b7b7;
  }

  .__sender__ids {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: flex-end;
  }

  .contains-sender-id-data {
    height: 50vh;
    overflow-y: scroll;
  }

  .is-sticky {
    position: sticky;
    top: 5%;
  }

  .contains-number {
    /* background-color: red; */
    display: flex;
    text-align: center;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .contains-number-link {
    width: fit-content;
    /* background-color: springgreen; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mr-4 button {
    display: flex;
    width: fit-content;
    padding: 0;
    margin: 0;
    cursor: initial;
  }

  svg *:hover {
    cursor: pointer;
    fill: red;
    transition: all 0.5s ease;
  }

  .__item_text {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
    align-items: center;
  }

  .scrollable {
    /* background-color: red; */
    overflow-y: scroll;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }

  .contains-hover:hover * {
    color: #f79b31;
    transition: all 0.2s ease;
  }

  a {
    text-decoration: none;
  }

  .group-close-button {
    background-color: #f79b31;
    border-color: #f79b31;
  }

  .group-close-button:hover {
    background-color: hsl(32, 93%, 68%);
    border-color: hsl(32, 93%, 68%);
  }
</style>
<template>
  <div class="scrollable">
    <small size="small" class="small-text">
      {{ $t("dashboard.overview.data_display") }}
    </small>
    <div>
      <div class="row justify-content-between details mb-5 align-items-center">
        <div class="col-sm-6 col-6 col-md-3 _ contains-number-link">
          <router-link
            class="_number_link"
            :to="
              logintype === 'sms'
                ? '/sms/campaign/history'
                : '/voice/campaign/history'
            ">
            <div class="contains-hover">
              <div class="contains-number">
                <p class="number">
                  {{ camapaignCount.toLocaleString() }}
                </p>
              </div>
              <div class="contains-number">
                <p class="title">
                  {{ $t("dashboard.overview.campaign_header") }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-sm-6 col-6 col-md-3 contains-number-link">
          <router-link class="v" to="/user/contacts">
            <div class="contains-hover">
              <div class="contains-number">
                <p class="number">
                  {{ contactsCount.toLocaleString() }}
                </p>
              </div>
              <div class="contains-number">
                <p class="title">
                  {{ $t("dashboard.overview.contacts_header") }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-sm-6 col-6 col-md-3 contains-number-link">
          <div class="contains-hover">
            <router-link
              to="/user/contacts"
              style="cursor: pointer"
              data-toggle="modal"
              data-target="#exampleModalLong">
              <div class="contains-number">
                <p class="number">{{ groupsCount.toLocaleString() }}</p>
              </div>
              <div class="contains-number">
                <p class="title">
                  {{ $t("dashboard.overview.groups_header") }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="col-sm-6 col-6 col-md-3 contains-number-link"
          v-if="logintype === 'sms'">
          <div class="contains-hover">
            <router-link class="_number_link" to="/wallet">
              <div class="contains-number">
                <p class="number">
                  {{ creditUsed.toLocaleString() }}
                </p>
              </div>
              <div class="contains-number">
                <p class="title">
                  {{ $t("dashboard.overview.credit_used_header") }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5" v-if="fetchingData">
      <div class="col-md-12" v-loading="fetchingData"></div>
    </div>
    <div class="row" v-else>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12 __sections">
            <div class="billing-summary mnotify-card">
              <div id="side-indicators-content" class="mnotify-card-header">
                <h3 class="mnotify-card-header-text">
                  {{ $t("dashboard.overview.billing_summary") }}
                </h3>
                <div class="side-indicators">
                  <p class="message-padding">
                    <span class="messages-round"></span>
                    <span class="messages-text">{{
                      $t("dashboard.overview.credit_used_header")
                    }}</span>
                  </p>
                  <p>
                    <span class="amount-round"></span>
                    <span class="amount-text">{{
                      $t("dashboard.overview.recipient")
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="mnotify-card-body">
                <div class="billing-card-inner">
                  <div class="__no-data" v-if="!categories.length">
                    <h3 class="__no-data-header">
                      {{ $t("dashboard.overview.no_data") }}
                    </h3>
                    <p class="__no-data-text">
                      {{ $t("dashboard.overview.send_messages") }}
                    </p>
                  </div>
                  <div class="__campaign__inner" v-if="categories.length">
                    <BillingHistoryChart
                      :categoriesData="categories"
                      :totalRecipients="totalRecipients" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="logintype == 'sms'">
          <div class="col-md-12 __sections contains-recent-campaigns-chart">
            <div class="campaigne mnotify-card">
              <div class="mnotify-card-header">
                <h3 class="mnotify-card-header-text">
                  {{
                    $t("dashboard.overview.recent_campaign", {
                      type: logintype == "sms" ? "SMS" : "VOICE",
                    })
                  }}
                </h3>
              </div>
              <div class="campaigne-card-inner">
                <div class="__no-data" v-if="!campaignSeries.length">
                  <h3 class="__no-data-header">
                    {{ $t("dashboard.overview.no_data") }}
                  </h3>
                  <p class="__no-data-text">
                    {{ $t("dashboard.overview.send_messages") }}
                  </p>
                </div>
                <div class="__campaign__inner" v-else>
                  <RecentSMSChart
                    :campaignLabels="campaignLabels"
                    :CampaignCount="campaignSeries" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-4 __sections sender-id-container"
        v-if="logintype === 'sms'">
        <div class="row">
          <div class="col-md-12">
            <div class="senderid mnotify-card" v-loading="loadingSenderIDS">
              <div
                class="mnotify-card-header _min_header d-flex justify-content-between align-items-center"
                style="flex-wrap: wrap; gap: 1rem">
                <h3
                  id="v-step-6"
                  class="mnotify-card-header-text __min_header_text is-sticky">
                  {{ $t("dashboard.overview.sender_id") }}
                </h3>
                <el-button
                  v-if="Array.isArray(senderIDs) && senderIDs.length > 0"
                  @click="openSenderID"
                  type="primary"
                  style="
                    width: auto;
                    height: 40px;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 14px;
                  "
                  class="is-sticky">
                  {{ $t("dashboard.overview.add_sender_id") }}
                </el-button>
              </div>
              <div
                class="senderid-card-inner"
                v-if="
                  Array.isArray(senderIDs) &&
                  senderIDs.length == 0 &&
                  !loadingSenderIDS
                ">
                <div class="__no-data notranslate">
                  <h3 class="__no-data-header">
                    {{ $t("dashboard.overview.no_sender_id") }}
                  </h3>
                  <p class="__no-data-text">
                    {{ $t("dashboard.overview.register_sender_id") }}
                  </p>
                  <el-button
                    @click="openSenderID"
                    type="primary"
                    style="
                      width: auto;
                      height: 38px;
                      border-radius: 5px;
                      font-weight: 600;
                      font-size: 14px;
                    ">
                    {{ $t("dashboard.overview.add_sender_id") }}</el-button
                  >
                </div>
              </div>
              <div
                class="__sender_id_data contains-sender-id-data"
                v-if="Array.isArray(senderIDs) && senderIDs.length > 0">
                <div class="__sender__ids notranslate">
                  <ul
                    class="__items notranslate"
                    v-for="(senderId, key) in senderIDs"
                    :key="key">
                    <li class="__item sender_item">
                      <div class="__item_text sender_item">
                        <span>{{ senderId.sender_id }}</span>
                        <button
                          type="button"
                          class="btn tool_tip_btn"
                          title="Pending"
                          data-toggle="tooltip"
                          data-placement="top"
                          v-if="senderId.status == 0">
                          <img
                            style="width: 18px; height: 18px"
                            src="../../../assets/Shield_pending.svg"
                            alt="pending icon" />
                        </button>
                        <button
                          type="button"
                          class="btn tool_tip_btn"
                          title="Approved"
                          data-toggle="tooltip"
                          data-placement="top"
                          v-if="senderId.status == 1">
                          <img
                            style="width: 18px; height: 18px"
                            src="../../../assets/Shield Done.svg"
                            alt="approved icon" />
                        </button>
                        <button
                          type="button"
                          class="btn tool_tip_btn"
                          title="On-hold pending verification"
                          data-placement="top"
                          data-toggle="tooltip"
                          v-if="senderId.status == 2">
                          <img
                            style="width: 18px; height: 18px"
                            src="../../../assets/shield_declined.svg"
                            alt="declined" />
                        </button>
                        <button
                          type="button"
                          class="btn tool_tip_btn"
                          title="Declined"
                          data-placement="top"
                          v-if="senderId.status == 3"
                          data-toggle="tooltip">
                          <img
                            style="width: 18px; height: 18px"
                            src="../../../assets/shield_on_hold.svg"
                            alt="declined" />
                        </button>
                      </div>
                      <div class="__list_item_icon sender__icon mr-4">
                        <button @click="deleteAction(senderId.id, key)">
                          <!-- <img src="../../../assets/delete_sender_id.svg" alt=""> -->
                          <svg
                            width="17"
                            height="15"
                            viewBox="0 0 19 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="delete_icon_svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.6417 2.62642C18.0319 2.62642 18.3567 2.85504 18.3567 3.14594V3.4149C18.3567 3.69872 18.0319 3.93442 17.6417 3.93442H1.0192C0.628045 3.93442 0.303223 3.69872 0.303223 3.4149V3.14594C0.303223 2.85504 0.628045 2.62642 1.0192 2.62642H3.94357C4.53761 2.62642 5.0546 2.32844 5.18823 1.90801L5.34138 1.4253C5.57938 0.767761 6.36266 0.331055 7.25909 0.331055H11.4008C12.2875 0.331055 13.0796 0.767761 13.3088 1.39062L13.4727 1.9073C13.6053 2.32844 14.1223 2.62642 14.7173 2.62642H17.6417ZM16.156 12.4585C16.4613 10.4505 16.9959 5.67995 16.9959 5.63182C17.0154 5.48602 16.9481 5.348 16.8144 5.23688C16.671 5.13283 16.4896 5.07125 16.2896 5.07125H2.37789C2.17695 5.07125 1.98577 5.13283 1.85311 5.23688C1.7185 5.348 1.65217 5.48602 1.66192 5.63182C1.66371 5.64067 1.68289 5.80871 1.71496 6.08964C1.85741 7.33767 2.25417 10.8136 2.51055 12.4585C2.69199 13.6702 3.81862 14.4318 5.45053 14.4594C6.70983 14.4799 8.00717 14.487 9.33377 14.487C10.5833 14.487 11.8524 14.4799 13.1507 14.4594C14.8392 14.4389 15.9648 13.6907 16.156 12.4585Z"
                              fill="#D4D8E2" />
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SENDER ID MODAL -->
    <SenderIDModal ref="senderID" @hideModal="hideModal" :userData="data" />
  </div>
</template>

<style scoped>
  .modal-group-body {
    height: 20vh;
    overflow-y: scroll;
  }

  .contains-title {
    position: sticky;
    top: -1rem;
    background-color: #ffffff;
    height: 2rem;
  }

  .contains-groups p {
    display: flex;
    margin: 1rem 0;
    align-items: center;
    gap: 1rem;
  }
  .small-text {
    font-size: 0.8rem !important;
    color: var(--danger);
  }
</style>
